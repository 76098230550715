import CalciWoff2 from "../assets/fonts/Calci-Regular.woff2";
import SuisseIntlWoff2 from "../assets/fonts/SuisseIntl-Regular-WebXL.woff2";
import SuisseIntlMedWoff2 from "../assets/fonts/SuisseIntl-Medium-WebXL.woff2";
import SuisseIntlBoldWoff2 from "../assets/fonts/SuisseIntl-Bold-WebXL.woff2";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: '"SuisseIntl", "Calci"',
    fontWeightBold: 600,
    fontWeightLight: 400,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    button: {
      fontWeight: 400,
      fontSize: 16,
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#CEFF00",
    },
    success: {
      main: "#FFF",
    },
    warning: {
      main: "#ccc",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 800,
      lg: 1280,
      xl: 1840,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SuisseIntl';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${SuisseIntlWoff2}) format('woff2');
        }
        @font-face {
            font-family: 'SuisseIntl';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: url(${SuisseIntlMedWoff2}) format('woff2');
        }
        @font-face {
            font-family: 'SuisseIntl';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: url(${SuisseIntlBoldWoff2}) format('woff2');
        }
        @font-face {
            font-family: 'Calci';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: url(${CalciWoff2}) format('woff2');
          }
      `,
    },
    MuiAppBar: {
      styleOverrides: { root: { height: 52, padding: 0, background: "#000" } },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "52px !important",
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            opacity: 1,
            background: "#4500ff",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          position: "absolute",
          left: 0,
          marginLeft: 10,
        },
        endIcon: {
          position: "absolute",
          right: 0,
          marginRight: 10,
        },
        outlined: {
          border: "1px solid #00000040",
          padding: "1px 15px",
        },
        root: {
          borderRadius: 50,
          boxShadow: "0px 0px 2px #00000040",
          padding: "0px 12px 0px 12px",
          lineHeight: "30px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: "#000000 !important",
          opacity: 0.5,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 16,
          marginTop: "13px !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          marginTop: "0px !important",
          padding: "4px 0px 5px 4px",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: "#000",
          opacity: "0.8 !important",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          width: "min(550px, 100%) !important",
          margin: "30px 10px",
          overflowY: "hidden",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          height: 52,
          padding: 11,
          boxShadow: "0px 2px 6px #00000029",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          height: 60,
          boxShadow: "0px -2px 6px #00000029",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          minHeight: 200,
          paddingTop: "16px !important",
          padding: 16,
          overflowY: "auto",
          maxHeight: "calc(100dvh - 112px)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#4500ff",
          borderRadius: 100,
          color: "#fff",
          fontSize: 16,
          fontWeight: 400,
          padding: "5px 15px",
        },
      },
      defaultProps: {
        leaveDelay: 200,
        PopperProps: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -6],
              },
            },
          ],
        },
      },
    },
  },
});
