import CookieConsent, { Cookies } from "react-cookie-consent";
import { DeviceUUID } from "device-uuid";

function CookieConsentDialog() {
  return (
    <CookieConsent
      acceptOnScroll={false}
      location="bottom"
      buttonText="Accept"
      cookieName="calci-cookie"
      enableDeclineButton={true}
      //debug={true}
      declineButtonText="Decline"
      style={{ background: "#2B373B", zIndex: 1500 }}
      expires={150}
      onAccept={() => {
        const uuid = new DeviceUUID().get();
        Cookies.set("deviceuuid", uuid);
      }}
      onDecline={() => {
        Cookies.remove("deviceuuid");
      }}
    >
      <p>
        <b>We value your privacy</b>
      </p>
      <p>
        We collect cookies to analyze our website traffic and performance; we
        never collect any personal data
      </p>
    </CookieConsent>
  );
}

export default CookieConsentDialog;
