import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { FC } from "react";

import { SpinnerProps } from "./spinner.props";

export const Spinner: FC<SpinnerProps> = ({ visible }) => {
  return (
    <Backdrop sx={{ zIndex: 5000, background: "#00000088" }} open={visible}>
      <CircularProgress color="inherit" size={60} />
    </Backdrop>
  );
};
