import { FC, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  PATH_ANY,
  PATH_HOME,
  PATH_MICROSITE_USER,
  PATH_MICROSITE_CATEGORY,
  PATH_HOME_EN,
  PATH_NOT_FOUND,
} from "./paths";
import { Spinner } from "../components";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<Spinner visible={true}></Spinner>}>
      <Component {...props}></Component>
    </Suspense>
  );

const MicrositePage = Loader(
  lazy(() => import("../pages/microsite/microsite.page"))
);
const NotFoundPage = Loader(
  lazy(() => import("../pages/not-found/not-found.page"))
);

const HomePage = Loader(lazy(() => import("../pages/home/home.page")));
const HomeENPage = Loader(lazy(() => import("../pages/home/home.page.en")));

export const AppRouter: FC = () => {
  return (
    <Routes>
      <Route path={PATH_HOME} element={<HomePage />} />
      <Route path={PATH_HOME_EN} element={<HomeENPage />} />
      <Route path={PATH_NOT_FOUND} element={<NotFoundPage />} />
      <Route path={PATH_MICROSITE_USER} element={<MicrositePage />} />
      <Route path={PATH_MICROSITE_CATEGORY} element={<MicrositePage />} />
      <Route path={PATH_ANY} element={<NotFoundPage />} />
    </Routes>
  );
};
